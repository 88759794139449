footer{
    border-top: solid 4px #B3B3B3;
}

.clickableImg{
    width: 23%;
    height: 80%;
}

li a {
    text-decoration: none;
    color: #000000;
    font-size: 1.2rem
}

li a:hover{
    color: rgb(72, 185, 72);
    transition: ease-in-out all 0.2s;
}

.mapImg{
    max-width: 100%;
    max-height: 100%;
    border: solid 2px #F2F2F2;
    border-radius: 8px;
}

.mapImg:hover{  
    transform: scale(1.09);
    transition: linear all 0.3s;
}

.footer{
    font-size: 1.5em;
    height: 250px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    bottom: 0;
    margin-bottom: 0;
    padding: 15px 0 25px 0;
    margin-top: 0;
    gap: 25px
}

div .list-section{
    text-align: center;
    white-space: "normal"
}

li{
    list-style: none;
    text-align: left;
}

.developer{
    text-decoration: none;
    font-family: 'Libre Baskerville', serif;
    color: white
}

.developer:hover {
    color:rgb(0, 0, 0);
    transition: ease-in-out all 0.3s;
}

footer .social-media{
    color: white;
    margin-top: 0;
    padding-left: 15px;
    /* padding-top: 5px; */
    /* padding-bottom: 5px; */
    background-color: #82BF26;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.social-media p{
    margin: 0
}

.footer h5{
    padding-top: 40px;
    margin-bottom: 30px;
}

.footer li span{
    margin-right: 6px;
}

.list-section div ul li{
    display: flex;
    align-items: center;
}

.list-section div ul li a{
    margin: 2px
}

.leftImages{
    width: 23%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.logos{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.logo{
    /* font-size: 2em !important; */
    max-width: 13%;
    border-radius: 50%;
}

.logo:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: ease-in-out all 0.2s ;
}

.palmasLogo{
    max-width: 40%; 
    max-height: 100%;
}

.palmasLogo:hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: ease-in-out all 0.2s ;
}

div ul li{
    font-weight: 700;
}

ul li h5{
    font-weight: 500;
}

@media (max-width: 1199.98px){
    .footer ul{
        width: 200px;
        /* margin-left: auto;
        margin-right: auto; */
    }

    .footer ul li a{
        width: fit-content;
    }
    
    ul li h5{
        width: 100%;
        /* text-align: center; */
    }
}

@media (max-width: 991.98px){
    .list-section{
        width: 25rem
    }

    .footer{
        gap: 10px
    }
    .list-section div li button{
        font-family: 'Gochi Hand', cursive !important;
        text-transform: none;
        font-size: 0.99em !important;
        margin: 0;
        padding: 0;
        transform: translateX(14px);
    }
    .list-section div li span button{
        font-family: 'Gochi Hand', cursive !important;
        text-transform: none;
        font-size: 0.99em !important;
        margin: 0;
        padding: 0;
        transform: none;
    }
}

@media (max-width: 767.98px){
    li a{
        font-size: 15px;
    }

    .footer{
        height: auto;
    }

    .footer h5{
        font-size: 15px;
        width: 80%;
    }

    .clickableImg{
        margin-right: 20px;
    }

    footer{
        width: 100%
    }

    .list-section{
        width: 15rem
    }

    .list-section h5{
        font-size: 20px;
        width: 50%;
    }  

    .footer{
        align-items: flex-start;
        width: 100;
        justify-content: center;
        font-size: 24px;
    }

    .list-section ul{
        margin: 0;
        padding: 0;
    }
    .list-section div{
        margin: 10px
    }

    .list-section ul li{
        font-size: 0.6em;
    }

    .list-section div ul{
        margin-top: -10px;
    }

    .social-media{
        font-size: 15px;
    }

    .social-media p{
        margin-left: -15px;
    }

    .logo{
        font-size: 1em !important;
    }

    .list-section div li button{
        font-family: 'Gochi Hand', cursive !important;
        text-transform: none;
        font-size: 0.99em !important;
        margin: 0;
        padding: 0;
        transform: translateX(5px);
    }
    .list-section div li div{
        margin: 0;
    }
}

@media (max-width: 575.98px){
    footer{
        width: 100%;
        height: auto;
    }
    .list-section h5{
        font-size: 0.75em;
        width: 50%;
    }  

    .footer{
        flex-direction: column;
        align-items: center;
        width: 100;
        justify-content: center;
        height: auto;
    }

    .palmasLogo{
        max-width: 90%;
    }

    .footer ul li{
        text-align: center;
    }

    .footer ul li h5{
        margin-left: auto;
        margin-right: auto;
    }

    .list-section ul{
        margin: 0;
        padding: 0;
    }
    .list-section div{
        margin: 10px
    }

    .list-section ul li{
        font-size: 0.6em;
    }

    .list-section div ul{
        margin-top: -10px;
    }

    footer .social-media{
        font-size: 0.8em;
        padding: 0;
    }

    .social-media p{
        margin-left: -10px;
        width: auto;
    }

    .clickableImg{
        width: 60%;
        margin: 0px
    }

    .logo{
        /* font-size: 1em !important; */
        max-width: 30%;
    }
}

@media (max-width: 370px){
    .logo{
        font-size: 0.8em !important;
    }
}

