@keyframes move {
    0% {
      transform: translate(-30%, 0);
    }
    50% {
      text-shadow: 0 25px 50px rgba(0, 0, 0, 0.75);
    }
    100% {
      transform: translate(30%, 0);
    }
  }

#container{
    text-align: center;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#container div h1{
    border: none !important;
    box-shadow: none !important;
}

.words span {
    font-size: 5rem;
    display: inline-block;
    animation: move 3s ease-in-out infinite;
  }

  .words span:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .words span:nth-child(3) {
    animation-delay: 1s;
  }
  
  .words span:nth-child(4) {
    animation-delay: 1.5s;
  }
  
  .words span:nth-child(5) {
    animation-delay: 2s;
  }
  
  .words span:nth-child(6) {
    animation-delay: 2.5s;
  }
  
  .words span:nth-child(7) {
    animation-delay: 3s;
  }
  