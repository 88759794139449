.paginator {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  top: -2rem;
  left: 50%;
  transform: translateX(-50%);

  width: 100%;
  max-width: 30rem;
  height: 2rem;
}

.hr {
  position: absolute;

  width: 100%;
  height: 2px;

  background-color: #82BF26;
}

.dot {
  height: 1rem;
  width: 1rem;

  border-radius: 50%;

  background-color: #82BF26;
  z-index: 1;

  transition: all 1s;
}

.dot:hover {
  cursor: pointer;
}

.dot.active {
  box-shadow: 0 0 0 4px #D91604;
  background-color: white;
  border: none !important
}