input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
  }

  .App{
    width: 100%;
    text-align: center;
  }

/*   h1{
    color: #2e7d32;
    border-bottom: solid #3b7302 4px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    padding-bottom: 5px;
  } */

