.slide-container{
    max-height: 100%;
}

.images-wrap{
    max-height: 100%;
    height: auto;
    align-items: stretch;
}

.images-wrap div{
    display: flex !important;
    align-items: stretch;
}

.images-wrap div img{
    flex-grow: 200;
    aspect-ratio: 3/2;
    object-fit: cover;
}

.each-slide {
    width: 100%;
}

.each-slide div{
    height: 100%;
    width: 100%;
}


.slideNav{
    width: 100%;
    /* margin-bottom: 40px;  */
}

.slideNav img{
    height: 500px !important;
    aspect-ratio: 3/2;
    object-fit: cover;
}

@media(max-width: 1199.98px){
    .slide-container{
        width: 100%;
        height:auto;
    }
}

@media(max-width: 991.98px){
    .slide-container{
        margin-left: auto;
        margin-right: auto;
    }
    .slide{
        height: 400px;
    }

}
@media(max-width: 767.98px){

    .slideNav img{
        object-fit: cover;
    }
    
}

@media(max-width: 575px){
    .slideNav{
        height: auto;
    }

    .slide-container{
        width: 100% !important;
    }

    .slideNav img{
        object-fit: cover;
    }
    
}
