*{
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "ShadedLarch";
  src: local("ShadedLarch"),
    url("../fonts/ShadedLarch.ttf") format("truetype");
}

@font-face {
  font-family: "Myriad Pro Regular";
  src: local("MyriadPro"),
    url("../fonts/Myriad Pro Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Myriad Pro Bold";
  src: local("MyriadPro"),
    url("../fonts/Myriad Pro Bold.ttf") format("truetype");
}

@font-face {
  font-family: "New Gothic Condensed";
  src: local("NewsGothicCondensedBQ-Regular"),
    url("../fonts/NewsGothicCondensedBQ-Regular.otf") format("opentype");
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  overflow-x: hidden;
  /* background-image: url("../images/Shiny\ Overlay\(4\).png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; 
  padding-right: 0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  max-width: 100%;
  overflow-x: hidden;
}